@import "/src/assets/styles/common";

.films {
    @include gradientPublicPrivate;
    color: $site-color;
    font-family: $primary-font-family;
    font-size: $text-md;
    
    &__button {
        @include button;
        margin: 0 auto;
        margin-bottom: $space-xxl;
    }

    &__content {
        @include flex-center;
        flex-direction: column;
        margin-top: $space-xl;
        padding: 0 $space-xl;
    }

    &__img--key {
        border-radius: 3%;
    }

    &__intro-container {
        margin: $space-xxl auto $space-xl;
        max-width: 600px;
        text-align: center;
        width: 100%;
    }

    @media (max-width: 768px) {
        &__content {
            padding: 0 $space-md;
        }
    }

    @media (max-width: 425px) {
        &__banner {
            background-position: 17%;
        }
    }
}