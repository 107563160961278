@import "/src/assets/styles/common";

.bodywork {
    background-color: #232023;
    font-family: $primary-font-family;
    color: $site-color;
    font-size: $text-md;
    line-height: 2.5rem;

    &__button--consult {
        border-bottom: 1px solid $site-color;
        margin: ($space-xs * 6) 0;
        padding-bottom: $space-xs * 6;

        a {
            @include button;
            color: $black;
            font-size: $text-xl;
            text-decoration: none;
        }
    }

    &__content {
        margin: 0 auto;
        max-width: 696px;
        padding: 0 $space-lg;
    }

    &__copy-clipboard {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: $space-md;
        max-width: 460px;
        width: 100%;
    }

    &__header {
        @include flex-center;
        @include title-text;
        background: url(/assets/images/hands-on-head.jpg);
        background-size: cover;
        background-position: 50%;
        flex-direction: column;
        font-size: $text-lg * 2;
        line-height: 5rem;
        min-height: 100vh;
        padding: 0 $space-lg;
        text-align: center;
        text-shadow: $text-shadow;

        div {
            font-size: $text-xxl;
        }
    }
    
    &__heading {
        margin: ($space-lg * 2) 0 $space-lg;
    }

    &__heading--society {
        margin: ($space-lg * 4) 0 $space-lg;
    }
    
    &__message {
        font-style: italic;
    }

    &__text {
        margin-bottom: $space-md;
    }

    &__text--accommodate {
        margin: $space-md 0 ($space-xs * 6)
    }

    &__text--contact {
        @include anchor;
        cursor: pointer;
        font-size: $text-lg;
        text-decoration: underline;
    }

    &__text-copied--hide {
        color: transparent;
    }

    &__text-copied--show {
        color: $site-color-light;
    }

    &__anchor--text {
        @include anchor;
    }

    @media (max-width: 768px) {
        font-size: $text-sm;

        &__content,
        &__header {
            padding: 0 $space-sm;
        }

        &__header {
            font-size: $text-xxl;

            div {
                font-size: $text-xl;
            }
        }

        &__text--contact,
        &__button--consult a {
            font-size: $text-md;
        }
    }

    @media (max-width: 425px) {
        &__copy-clipboard {
            flex-direction: column;
            justify-content: center;
        }

        &__heading--society {
            margin-top: $space-lg;
        }    
        
        &__button--consult {
            @include flex-center;
        }
    }
}
