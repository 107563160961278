@import "/src/assets/styles/common";

.origine {
    @include gradientPublicPrivate;
    color: $site-color;
    font-family: $primary-font-family;

    &__calendar-button {
        @include button;
        margin-top: $space-xl;
    }

    &__header {
        @include flex-center;
        flex-direction: column;
        height: 100vh;
        padding: 0 $space-lg;
        position: relative;
        width: 100%;
    }

    &__heading {
        @include flex-center;
        margin-top: $space-lg;
    }

    &__image--full {
        margin-top: 135px;
    }

    &__link-container {
        @include flex-center;
        justify-content: space-around;
        margin: $space-xxl 0;
        max-width: 720px;
        width: 100%;
    }

    &__logo--header {
        display: block;
        max-width: 452px;
        width: 100%;
        z-index: 2;
    }
    &__video-overlay--header {
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 1;
    }
    &__video-player--header,
    &__video-overlay--header {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
    &__video-player--header {
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;
        z-index: 0;
    }

    &__content {
        @include flex-center;
        flex-direction: column;
        margin: 120px 0 100px;
    }

    &__press {
        @include flex-center;
        flex-direction: column;
    }
    
    &__press > div {
        margin-bottom: $space-xl;
    }

    &__press-source {
        display: flex;
        flex-direction: column;
        margin: $space-md 0;

        span:first-child {
            font-size: $text-lg;
            font-weight: $bold-font-weight;
        }
    }

    &__text {
        font-size: $text-md;
        line-height: 2.5rem;
        max-width: 696px;
        width: 100%;
    }

    &__media-row {
        display: flex;
        justify-content: space-evenly;
        padding: 0 $space-lg;
        width: 100%;

        img {
            width: 50%;
            margin: $space-sm;
        }
        
        &--one {
            justify-content: center;
            margin-top: 0;
            margin-bottom: 65px;
            padding-bottom: 0;
            
            .origine__video-column {
                width: 80%;
            }
        }
        
        &--two {
            margin: 135px auto;
            padding: 0;
            img {
                margin: 0;
            }
        }
        
        &--five {
            display: flex;
            justify-content: center;
            margin: 0 auto 120px;

            .origine__video-column {
                max-width: 600px;
                width: 100%;

                &:first-of-type {
                    margin-right: $space-sm;
                }
            }
        }

        &--one,
        &--three,
        &--four {
            max-width: 1440px;
        }
    }

        
    &__quote {
        @include flex-center;
        background: url(/assets/images/purple-stems.jpg);
        background-position: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        flex-direction: column;
        min-height: 572px;
        margin-bottom: $space-lg * 4;
    }

    &__quote-container {
        max-width: 1250px;
        padding: 0 $space-lg;
    }

    &__quote-text {
        font-family: $secondary-font-family;
        font-weight: $bold-font-weight;
        font-size: $text-xl * 2;
        line-height: 60px;
    }

    &__quote-source {
        font-size: $text-sm;
        line-height: 25px;
        margin-top: $space-sm;
        max-width: 580px;
    }

    &__scroll-heading {
        @include flex-center;
        margin: $space-xl 0;
        width: 100%;
    }

    @media (max-width: 768px) {
        &__image--full {
            margin-bottom: $space-xxs;
        }

        &__media-row {
            align-items: center;
            flex-direction: column;
            padding: 0;

            img,
            .origine__video-column {
                margin-top: $space-md;
                padding: 0;
                width: 80%;
            }

            &--five {
                .origine__video-column {
                    &:first-of-type {
                        margin-right: 0;
                    }
                }
            }
        }

        &__quote-source {
            text-align: right;
        }

        &__quote-text {
            font-size: $text-xxl;
            line-height: 45px;
        }

        &__text {
            padding: 0 $space-md;
        }
    }

    @media (max-width: 446px) {
        &__content {
            @include flex-center;
            flex-direction: column;
            margin: $space-xl 0 $space-lg;
        }

        &__text {
            font-size: $text-sm;
            line-height: 20px;
        }
        &__controls {
            align-items: center;
            flex-direction: column;
        }
        &__button-spacer {
            height: 50px;
        }

        &__image--full {
            margin-top: $space-xl;
        }


        &__media-row--three {
            margin-bottom: 0;
        }
        
        &__media-row--four {
            margin-top: 0;
            margin-bottom: $space-sm;
        }

        &__quote {
            height: 400px;
        }

        &__quote-container {
            padding: 0 $space-xs;
        }

        &__quote-text {
            font-size: $text-lg;
            line-height: 32px;
        }

    }
}