@import "/src/assets/styles/common";

.support-banner {
    margin-bottom: $space-xxl;
    padding: $space-lg;
    width: 100%;

    &__heading {
        font-size: $text-sm;
        margin-right: $space-md;
        width: 180px;
    }

    &__logo {
        height: 100%;
        max-height: 60px;
        max-width: 100%;

        &--fta {
            max-width: unset;
            max-height: unset;
            height: 100px;
            width: 100px;
        }
    }

    &__logo-container {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }

    &__row {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 900px;
        padding: $space-lg $space-sm;
    }

    @media (max-width: 1024px) {
        &__logo {
            max-height: 50px;
        }
    }

    @media (max-width: 768px) {
        &__heading {
            margin-right: 0;
            text-align: center;
            width: 180px;
        }

        &__row,
        &__logo-container {
            align-items: center;
            flex-direction: column;
            padding: 0;
        }

        &__logo {
            &--fta {
                height: 90px;
                width: 90px;
            }
        }

        img {
            margin: $space-lg 0;
        }
    }

    @media (max-width: 425px) {
        margin-bottom: 0;
    }
}
