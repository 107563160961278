@import "/src/assets/styles/common";

.interviews {
    @include gradientPublicPrivate;
    color: $site-color;
    font-family: $primary-font-family;
    font-size: $text-md;

    &__button {
        @include button;
    }

    &__header {
        @include title-text;
        @include flex-center;
        position: relative;
        height: 100vh;
    }
    &__header-text {
        font-size: $text-xl * 2;
        padding: 0 $space-lg;
        text-align: center;
        z-index: 2;
    }

    &__heading {
        display: flex;
        flex-direction: column;
        font-size: 4.6rem;
        font-weight: $base-font-weight;
        line-height: 48px;
    }
    &__video-header {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
    &__video-header,
    &__video-header-overlay {
        position: absolute;
        z-index: 1;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
    &__video-header-overlay {
        background-color: rgba(0, 0, 122, 0.2);
    }

    &__video-container {
        width: 50%;
    }
    
    &__video-container--wide {
        max-width: 800px;
        width: 100%;
    }

    &__intro {
        max-width: 730px;
        margin: ($space-lg * 4) auto ($space-lg * 5);
        font-size: $text-xl;

        p:first-of-type {
            margin-bottom: $space-sm;
        }
    }

    &__videos {
        max-width: 1135px;
        margin: 0 auto;
        padding: 0 $space-sm;
    }
    &__videos-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 220px;
    }
    &__videos-text {
        flex: 1;
        font-size: $text-md;
        line-height: 25px;
        max-width: 730px;
        margin-bottom: $space-lg;
    }

    @media (min-width: 1170px) {
        &__videos-row {
            flex-direction: row;
        }
        &__videos-text {
            margin-bottom: 0;
        }
        &__videos-row--left &__videos-text {
            margin-left: $space-lg * 3;
            order: 2;
        }
        &__videos-row--right &__videos-text {
            margin-right: $space-lg * 3;
        }
        &__videos-row--center {
            flex-direction: column;
        }
        &__videos-row--center &__videos-text {
            max-width: 800px;
            margin-top: $space-lg;
            order: 2;
        }
        &__videos-row--center &__video-player {
            max-width: 800px;
            height: 450px;
        }
    }
    &__videos-text h2 {
        font-family: $secondary-font-family;
        letter-spacing: 2px;
        margin-bottom: $space-md;
        padding: 0;
    }

    &__logos-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 900px;
        margin: $space-lg auto;

        img {
            max-height: 60px;
        }
    }

    @media (max-width: 1170px) {
        &__video-container {
            width: 80%;
        }
    }

    @media (max-width: 768px) {
        font-size: $text-sm;
        
        &__header {
            height: 400px;
            font-family: $secondary-font-family;
        }
        
        &__header-text {
            font-size: $text-lg * 2;
        }
        
        &__intro {
            font-size: $text-sm;
            padding: 0 $space-sm;
        }

        &__logos-row {
            align-items: center;
            flex-direction: column;
        }
        &__support img {
            margin: $space-lg 0;
        }
        &__video-container {
            width: 100%;
        }

        &__videos-row {
            margin-bottom: $space-xxl;
        }

        &__videos-text {
            font-size: $text-sm;
        }

        &__videos-text h2 {
            font-size: $text-sm * 2;
            line-height: 35px;
        }
    }

    @media (max-width: 768px) {
        &__intro {
            margin: $space-lg auto $space-xl;
        }
    }
}
