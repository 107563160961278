@import "/src/assets/styles/common";

.personnel {
    font-family: $primary-font-family;
    font-size: $text-sm;
    line-height: 27px;
    margin: 0 auto;
    margin-bottom: $space-xl * 2;
    max-width: 860px;
    padding: 0 $space-lg;

    &__heading {
        font-weight: $bold-font-weight;
        margin-top: $space-xl + 8;
        margin-bottom: $space-lg - 2;
        text-transform: uppercase;

        &:first-of-type {
            margin-top: unset
        }
    }

    &__heading--main {
        text-transform: unset;
    }

    &__heading--collab {
        margin-top: $space-lg - 2;
    }

    &__link {
        @include anchor;
    }

    &__role--interview {
        text-transform: capitalize;
    }

    &__row {
        display: flex;
        width: 100%;
    }

    &__row span:nth-of-type(2) {
        width: 100%;
        border-bottom: 2px dotted #9d8b02;
        margin: 0 2px 9px;
    }

    &__row span:nth-of-type(1),
    &__row span:nth-of-type(3) {
        white-space: nowrap;
    }

    &__row--special {
        margin-top: $space-lg;
    }

    @media (max-width: 1024px) {
        max-width: 660px;
        padding: $space-sm;

        &__row {
            flex-direction: column;
            margin: $space-sm 0;
        }

        &__row span:nth-of-type(1) {
            font-weight: $bold-font-weight;
        }

        &__row span:nth-of-type(2) {
            border: none;
            margin: 0;
        }

        &__row span:nth-of-type(3) {
            line-height: 20px;
        }

        &__row span:nth-of-type(1),
        &__row span:nth-of-type(3) {
            white-space: normal;
        }
    }

    @media (max-width: 425px) {
        margin-bottom: $space-xl;
    }
}
