@import "/src/assets/styles/common";

.footer {
    color: $site-color;
    display: flex;
    font-size: $text-sm;
    height: 235px;
    justify-content: space-evenly;
    padding-top: $space-xl;
    width: 100%;

    &--blue {
        background-color: #051320;
    }

    &--pink {
        color: #000225;
        background-color: #A60046;
    }

    &__anchor--icon {
        margin: $space-xxs;
        padding: $space-xxs;
        width: 50px;

        svg {
            font-size: $text-xxl;
            margin: 0 auto;
        }
    }

    &__column {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 205px;
    }

    &__email-span {
        cursor: pointer;
    }

    &__text-copied {
        text-align: center;
    }

    &__text-copied--hide {
        color: transparent;
    }

    &__text-copied--show {
        color: $site-color-light;
    }

    &__twitter-container {
        margin-bottom: $space-sm;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            color: inherit;
        }
    }

    @media (max-width: 768px) {
        align-items: center;
        flex-direction: column;
        height: 600px;
        margin-top: 0;
        text-align: center;
    }
}
