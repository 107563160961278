@import "/src/assets/styles/common";

.films-podcast-entry {
    @include flex-center;
    flex-direction: column;
    margin-bottom: $space-xxl * 2;
    max-width: 1133px;
    width: 100%;

    &:last-of-type {
        margin-bottom: $space-xxl;
    }

    &__credits {
        font-size: $text-xs;
        
        div:first-of-type {
            margin-bottom: $space-sm;
        }
    }

    &__credits-container {
        margin-top: $space-lg;

        h5 {
            margin-bottom: $space-sm;
        }
    }

    &__heading {
        font-family: $secondary-font-family;
        font-size: $text-xl * 2;
        font-weight: $base-font-weight;
        margin-bottom: $space-lg;
    }

    &__links-container {
        display: flex;
        justify-content: space-evenly;
        margin: 0 auto;
        margin-top: $space-xl;
        max-width: 400px;
        width: 100%;

        a {
            @include button;
        }
    }


    &__player {
        @include flex-center;
        margin-bottom: $space-lg;
        max-width: 1230px;
        width: 100%;
    }

    &__text {
        max-width: 700px;
        text-align: center;
        width: 100%;
    }

    @media (max-width: 768px) {
        &__heading {
            font-size: $text-xxl;
        }

        &__links-container {
            a {
                font-size: $text-md;
            }
        }
    }
    
    @media (max-width: 425px) {
        &__links-container {
            a {
                font-size: $text-xs;
            }
        }
    }
}