@import "/src/assets/styles/common";

.language-button {
    background: none;
    border: none;
    color: $site-color;
    cursor: pointer;
    margin-right: $space-lg;
    position: relative;
    text-align: center;
    text-shadow: $text-shadow;
    z-index: 2;
}
