@import "/src/assets/styles/common";


.calendar {
    @include flex-center;
    background-color: $black;
    color: $site-color;
    flex-direction: column;
    height: 100%;
    padding: $space-xl * 3 0 $space-xl;

    &__cell, &__cell--project {
        font-size: 15px;
        width: 85px;
    }

    &__cell--project {
        width: 140px;
    }

    &__container {
        color: $site-color;
        max-width: 1000px;
        margin: $space-xl 0 $space-xl * 2;
        padding: 0 $space-xl;
        width: 100%;
    }

    &__entry, &__heading {
        display: flex;
        justify-content: space-between;
        padding: $space-lg 0;
        width: 100%;
    }

    &__entry {
        border-bottom: 1px solid $site-color;

        &:last-of-type {
            border-bottom: none;
        }

        &--past {
            border-color: $gray;
            color: $gray;
        }
    }

    &__heading {
        border-bottom: 3px solid $site-color;
    }

    &__link {
        text-decoration: none;

        div {
            @include button;
            font-size: $text-xs;
            height: unset;
            padding: 0 $space-sm;
        }
    }

    &__title {
        margin-bottom: $space-xxl;
        font-family: $secondary-font-family;
    }

    @media (max-width: 768px) {
        &__cell, &__cell--project {
            margin-bottom: $space-xs;
            width: 100%;
        }

        &__container {
            padding: 0 $space-md;
        }    

        &__entry {
            flex-direction: column;
        }
    
        &__heading {
            display: none;
        }
    }
}