@import "/src/assets/styles/common";

.lightbox-gallery {
    @include flex-center;
    width: 100%;
    
    &__album {
        width: 100%;
        
        &--origine {
            max-width: 1000px;
        }

        &--principle {
            max-width: 600px;
        }
    }
}