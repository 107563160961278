@import "/src/assets/styles/common";

.hamburger-button {
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 1.5em 0.8em;
    position: relative;
    z-index: 2;

    &__burger {
        display: block;
        position: relative;
    }

    &__burger,
    &__burger::before,
    &__burger::after {
        width: 2em;
        height: 2px;
        background-color: $site-color;
        box-shadow: $text-shadow;
    }

    &__burger::before,
    &__burger::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        transition: transform 300ms ease-in-out;
    }

    &__burger::before {
        top: 8px;
    }

    &__burger::after {
        bottom: 8px;
    }

    &__burger--open {
        transform: rotate(45deg);
    }

    &__burger--open::before {
        transform: rotate(90deg) translateX(-8px);
    }

    &__burger--open::after {
        opacity: 0;
    }
}
