@import "/src/assets/styles/common";

.socials-row {
    display: flex;
    justify-content: space-around;
    margin-top: $space-sm;
    max-width: 260px;
    width: 100%;
    
    svg {
        font-size: $text-xs * 2;
    }
}