@import "/src/assets/styles/common";

.principle {
    background-color: #232023;
    color: $site-color;
    font-family: 'Eczar', serif;
    font-size: $text-lg;
    font-weight: $bold-font-weight;
    line-height: 2.5rem;

    &__button {
        @include button;
        font-size: $text-md;
    }

    &__calendar-button {
        @include button;
        background-color: #F14A4A;
        border-color: #F14A4A;
        color: $black;
        margin: 0 auto;
        margin-top: $space-xl;

        &:hover {
            background-color: #A60046;
            border-color: #A60046;
            color: $black;
        }
    }

    &__credits {
        margin-bottom: $space-xl;

        div {
            font-weight: $base-font-weight;
            margin: $space-xs 0;
        }

        h2 {
            margin-bottom: $space-xl;
            text-align: center;
        }
    }

    &__discover {
        font-weight: 500;
    }

    &__images {
        align-items: center;
        background-color: #A60046;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 1000px;
        padding: $space-sm;
    }
    
    &__image {
        margin: $space-xs 0;
        max-width: 475px;
        padding: 0 $space-xs;
        width: 100%;

        &--title {
            left: 50%;
            max-width: 500px;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
        }

        &--seven {
            max-width: 985px;
        }

    }

    &__intro {
        @include flex-center;
        background-color: #A60046;
        padding: 0 $space-xxl;
        width: 100%;
        
        img {
            height: 100%;
            max-width: 800px;
            position: relative;
            top: 50px;
            width: 100%;
            z-index: 2;
        }
    }

    &__link-container {
        @include flex-center;
        color: #F14A4A;
        justify-content: space-around;
        margin: $space-xl * 2 0;
        max-width: 720px;
        width: 100%;
    }

    &__link-target {
        color: $black;
        font-size: $text-xl * 2;
        padding: $space-xxl 0 $space-xl;
        text-align: center;
    }

    &__media {
       background-color: #A60046; 
       padding: 0 $space-sm;
       width: 100%;
    }

    &__press {
        color: #000225;
        font-weight: $base-font-weight;
        height: fit-content;
        margin: 0 auto;  
        max-width: 900px;
        padding-bottom: $space-xl;
        width: 100%;
    }

    &__press-entry {
        margin-bottom: $space-xl;

        div:first-of-type {
            margin-bottom: $space-md;
        }
    }

    &__press-source {
        display: flex;
        flex-direction: column;
    }

    &__trailers {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1200px;
        margin-top: 0;
        padding: $space-sm 0;
        width: 100%;
    }
    
    &__text-container {
        @include flex-center;
        background-image: linear-gradient(
            0deg,
            hsl(0deg 0% 0%) 33%,
            hsl(245deg 92% 7%) 92%,
            hsl(242deg 67% 9%) 100%
        );
        color: #BE0050;
        padding: 0 $space-md;
    }
    
    &__text {
        bottom: 100px;
        background-color: transparent;
        max-width: 650px;
        position: relative;
        text-align: center;
        width: 100%;
    }

    &__video-column {
        width: 48%;
    }

    @media (max-width: 1024px) {
    }
    
    @media (max-width: 973px) {
        &__images {
            justify-content: center;
        }

        &__image--seven {
            max-width: 475px;
        }
    }

    @media (max-width: 768px) {
        font-size: $text-md;

        &__link-target {
            font-size: $text-xxl;
        }

        &__trailers {
            flex-direction: column;
        }

        &__video-column {
            width: 100%;

            &:first-of-type {
                margin-bottom: $space-xl;
            }
        }
       
    }

}