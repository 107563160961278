@import "/src/assets/styles/common";

.podcast {
    @include gradientPublicPrivate;
    color: $site-color;
    font-family: $primary-font-family;
    font-size: $text-md;

    &__banner--inner {
        -webkit-box-shadow: -15px 0px 39px 8px rgba(0,0,0,0.8); 
        box-shadow: -15px 0px 39px 8px rgba(0,0,0,0.8);
        border-radius: 20px;
        max-width: 450px;
        width: 100%;
    }

    &__host {
        bottom: 7%;
        color: $black;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);

        div {
            font-family:$primary-font-family;
            line-height: 38px;
            text-shadow: none;
        }

        div:first-of-type {
            color: #000E89;
            font-size: $text-lg;
        }

        div:last-of-type {
            color:  #000E89;
            font-size: $text-md * 2;
        }
    }

    &__credits {
        font-size: $text-xs;

        div:first-of-type{
            margin-bottom: $space-sm;
        }
    }

    &__credits-container {
        margin: 0 auto $space-xxl;
        max-width: 700px;
        text-align: center;
        width: 100%;

        h5 {
            margin-bottom: $space-sm;
        }
    }
    
    &__content {
        @include flex-center;
        flex-direction: column;
    }

    &__intro-container {
        @include flex-center;
        flex-direction: column;
        margin-bottom: $space-xxl;
        max-width: 600px;
        width: 100%;
    }

    &__intro-heading {
        font-family: $secondary-font-family;
        font-size: $text-xxl * 2;
        font-weight: $base-font-weight;
        line-height: 80px;
        margin: $space-xl * 2 0 $space-xl;
        max-width: 500px;
        text-align: center;
        width: 100%;
        word-wrap: break-word;
    }

    &__intro-text {
        text-align: center;
    }

    &__platforms {
        bottom: 2%;
        display: flex;
        position: absolute;
        left: 2%;

        img {
            height: 100%;
            max-height: 30px;
            max-width: 100%;
            margin-right: $space-xs;
        }
    }

    @media (max-width: 768px) {
        &__content {
            padding: 0 $space-md;
        }

        &__intro-heading {
            font-size: $text-lg * 2;
            line-height: 50px;
            max-width: 400px;
        }

        &__host {
            bottom: 7%;
            div:last-of-type {
                font-size: $text-lg;
                line-height: 30px;
            }
        }
    }

    @media (max-width: 425px) {
        &__host {
            bottom: 12%;
        }
    }

}