// names defined with https://chir.ag/projects/name-that-color/

$site-color: #AB9700;
$site-color-light: #fff49e;
$site-background-blue: #041422;

$white: #ffffff;
$concrete: #f3f3f3;
$black: #000000;
$gray: #8d8d8d;
$light-gray: #c1c1c1;
$silver: #c4c4c4;
$gallery: #f0efef;
$magic-mint: #b7f5c9;
$atlantis: #7ac943;
$mountain-meadow: #18ae63;
$green-haze: #00a958;
$downy: #69cccc;
$science-blue: #0077dc;
$matisse: #236399;
$bay-of-many: #1f2f79;
$endeavour: #005cad;
$cornflower-blue: #6366f1;
$link-water: #deebf6;
$plum: #8e318b;
$tia-maria: #c43a0e;
$web-orange: #e8a700;
$jaffa: #f2994a;
$endeavour: #005cad;
$link-water: #deebf6;
