@import "/src/assets/styles/common";

.header {
    align-items: center;
    color: $site-color;
    display: flex;
    font-size: $text-sm;
    justify-content: space-between;
    left: 0;
    padding: $space-xs $space-sm;
    position: fixed;
    right: 0;
    text-transform: uppercase;
    top: 0;
    transition: background-color 0.2s ease-in-out;
    z-index: 10;

    &__branding a {
        color: $site-color;
        font-family: $secondary-font-family;
        font-size: $text-lg;
        font-weight: $bold-font-weight;
        text-decoration: none;
        text-shadow: $text-shadow;
        z-index: 2;
    }

    &__controls {
        display: flex;
    }

    &:hover {
        background-color: $black;
    }

    &--black {
        background-color: $black;
    }

    &--transparent {
        background-color: transparent;
    }

    @media (max-width: 768px) {
        &__branding {
            font-size: $text-md;
        }
    }
}
