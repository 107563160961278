@import "/src/assets/styles/common";

.public-private-support {
    display: flex;
    justify-content: space-evenly;
    margin: 0 auto $space-xl;
    max-width: 610px;
    width: 100%;
    
    &__logo {
        height: 100%;
        max-height: 60px;
        max-width: 100%;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        &__logo {
            max-height: 50px;

            &:first-of-type {
                margin-bottom: $space-xl;
            }
        }
    }
}