@import "/src/assets/styles/common";

.publicprivate {
    background-color: $black;
    color: $site-color;
    font-family: $primary-font-family;

    &__button a {
        @include button;         
        font-style: normal;
    }

    &__header {
        @include flex-center;
        background-color: $black;
        height: 400px;
        margin: 0 auto;
        padding-top: $space-xl;
        width: 100%;
        z-index: 2;
    }

    &__link-container {
        display: flex;
        justify-content: space-between;
        max-width: 720px;
        margin-bottom: $space-xxl;
        width: 100%;
        z-index: 2;
    }

    &__title {
        align-items: center;
        color: #9d8b02;
        display: flex;
        flex: 1;
        font-size: 1.7rem;
        justify-content: space-between;
        max-width: 425px;
        text-transform: uppercase;
        z-index: 1;
    }

    &__spacer--header {
        flex: 1;
    }

    &__video--banner {
        width: 100%;
    }

    &__content {
        position: relative;
        overflow: hidden;
    }

    &__content--inner {
        @include flex-center;
        flex-direction: column;
    }

    &__spacer--about {
        z-index: 2;
    }

    &__video--about {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1;
    }

    &__text {
        font-size: $text-md;
        max-width: 696px;
        text-align: center;
        z-index: 2;
        margin-bottom: $space-xxl * 2;

        p:first-of-type {
            margin-bottom: 16px;
        }
    }

    @media (max-width: 768px) {
        &__button a {
            font-size: $text-sm;
        }

        &__content {
            padding: $space-sm;
        }

        &__header {
            max-width: 260px;
        }

        &__link-container {
            align-items: center;
            flex-direction: column;
            height: 300px;
            margin-bottom: $space-xs;
        }

        &__title {
            font-size: 1.6rem;
        }

        &__text {
            font-size: $text-sm;
            margin-bottom: $space-xxl;
        }


        &__video--about {
            height: 100%;
            object-fit: contain;
        }
    }

    @media (max-width: 425px) {
        &__header {
            padding-top: $space-lg;
        }

        &__spacer--header {
            width: 20px;
        }
    }
}