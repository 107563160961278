@import "/src/assets/styles/common";

.loader {
    @include gradientHomepage180;
    @include flex-center;
    flex-direction: column;
    height: 100vh;
    margin: 0;
    width: 100%;

    img {
        padding: 0 $space-xl;
    }

    svg {
        @include spinning-loader;
    }
}
