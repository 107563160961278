@import "/src/assets/styles/common";

.contact-banner {
    @include flex-center;
    flex-direction: column;
    background: url(/assets/images/purple-flower.jpg) no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 0 $space-lg;
    height: 420px;
    line-height: 2em;
    width: 100%;

    &__text-copied {
        text-align: center;
    }

    &__text-copied--hide {
        color: transparent;
    }

    &__text-copied--show {
        color: $site-color-light;
    }

    &__title {
        @include title-text;
        cursor: pointer;
        font-size: $text-xl * 2;
        text-align: center;
        text-transform: none;
        margin-bottom: $space-md;

        &:hover {
            color: $site-color-light;
        }
    }

    a {
        @include anchor;
        padding: $space-xs;
    }

    @media (max-width: 768px) {
        &__title {
            font-size: $text-xl * 1.5;
        }
    }

    @media (max-width: 425px) {
        padding: 0 $space-sm;

        &__email {
            font-size: $text-sm;
        }
    }
}
