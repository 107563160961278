@import "/src/assets/styles/common";

.homepage {
    background-color: $black;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;

    &__background {
        bottom: 0;
        left: 50%;
        max-width: 600px;
        position: fixed;
        transform: translate(-50%, 0);
        width: 100%;
    }

    &__background-container {
        position: relative;
    }


    &__container {
        display: flex;
        max-width: 1440px;
        width: 100%;
    }
    
    &__img-1 {
        height: 120px;
        left: 140px;
        position: relative;
        top: 15px;
        width: 120px;
    }

    &__img-2 {
        height: 200px;
        width: 320px;
        border-radius: 9%;
    }

    &__img-3 {
        bottom: 20px;
        height: 135px;
        left: 80px;
        position: relative;
        width: 160px;
    }
    
    &__img-4 {
        height: 210px;
        width: 280px;
    }

    &__logo {
        position: absolute;
        top: 0;
        left: 0;
    }

    &__logo--mobile {
        max-width: 150px;
        width: 100%;
    }

    &__logo--ppp, &__logo--pop, &__overlay {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    
    &__logo--ppp, &__logo--pop {
        width: 95%;
        z-index: 2;
    }

    &__mobile-links {
        align-items: center;
        display: none;
        flex-direction: row;
        height: 45vh;
        justify-content: space-evenly;
        margin-top: $space-md;
        width: 100%;
        z-index: 1;
    }

    &__overlay-container {
        position: relative;
    }
    
    &__overlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.653);
        z-index: 1;
    }

    &__parallax-container {
        display: grid;
        grid-template-columns: auto auto;
        padding: 0 $space-xl * 2;
        width: 100%;
        z-index: 2;
    }

    &__parallax-child {
        @include flex-center;

        &:first-of-type{
            justify-content: flex-start;
            margin-top: $space-xxl * 2;
        }
        &:last-of-type{
            justify-content: flex-end;
        }
    }

    &__video {
        border-radius: 14%;
        height: 100%;
        width: 240px;
        bottom: 25px;
        left: 60px;
        position: relative;
    }

    @media (max-width: 768px) {
        &__parallax-container {
            display: none;
        }
    }

    @media (max-width: 425px) {
        &__mobile-links {
            display: flex;
        }
    }
}
