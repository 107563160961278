@import "/src/assets/styles/common";

.navmenu {
    background-color: $black;
    bottom: 0;
    display: flex;
    flex-direction: column;
    font-weight: $bold-font-weight;
    justify-content: space-between;
    left: 0;
    padding: $space-lg;
    position: fixed;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    z-index: 1;

    &--open {
        transform: translateX(0);
    }

    &__contact {
        align-items: center;
        align-self: flex-end;
        display: flex;
        font-family: $secondary-font-family;
        font-size: $text-sm;
        letter-spacing: 1px;
        margin-top: $space-xs;
        text-transform: none;

        svg {
            margin: 0 $space-xxs;
        }
    }

    &__top {
        display: flex;
        height: 50px;
        justify-content: flex-end;
        position: relative;
    }

    &__inner-list {
        list-style: none;
    }

    &__list {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        list-style-type: none;
        padding: 0;
        width: 100%;
    }

    &__list-item {
        font-family: $secondary-font-family;
        font-size: $text-xxl;
        line-height: 4rem;
        max-width: 710px;
    }

    &__list-item a {
        align-items: baseline;
        display: flex;
        text-transform: none;
    }

    &__list-item a,
    &__contact a {
        @include anchor;
        text-decoration: none;
    }

    &__list-item--numeral {
        font-family: $primary-font-family;
        font-size: 1.9rem;
        font-style: italic;
        margin-right: $space-lg;
    }

    @media (max-width: 768px) {
        padding: $space-sm;
    }

    @media (max-width: 767px) {
        &__contact {
            align-self: flex-start;
        }

        &__list-item {
            line-height: $text-xxl;
        }

        &__list-item--numeral {
            display: none;
        }
    }

    @media (max-width: 425px) {
        &__list-item {
            font-size: $text-lg;
            line-height: $text-xl;
            max-width: 300px;
        }

        &__contact {
            font-size: $text-xs;
        }
    }
}
