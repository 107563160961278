$primary-font-family: "AktivGrotesk", "Questrial", sans-serif; // aktiv grotesk

$secondary-font-family: "Ogg", "Tinos", serif; // Ogg

$root-font-size: 16px;

$light-font-weight: 100;

$base-font-weight: 400;

$bold-font-weight: 700;

$text-shadow: 1px 1px 2px $black;

// font-sizing start

$text-xxs: 1.2rem;

$text-xs: 1.4rem;

$text-sm: 1.6rem;

$text-md: 1.8rem;

$text-lg: 2.1rem;

$text-xl: 2.4rem;

$text-xxl: 3.2rem;

// font-sizing end
