$space-unit: 16px;

$space-xxs: calc(0.25 * $space-unit);

$space-xs: calc(0.5 * $space-unit);

$space-sm: calc(0.75 * $space-unit);

$space-md: calc(1.25 * $space-unit);

$space-lg: calc(2 * $space-unit);

$space-xl: calc(3.25 * $space-unit);

$space-xxl: calc(5.25 * $space-unit);
